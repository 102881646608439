@tailwind base;
@tailwind components;
@tailwind utilities;
/* press-start-2p-regular - latin */
@font-face {
  font-family: 'Press Start 2P';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/press-start-2p-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/press-start-2p-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/press-start-2p-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/press-start-2p-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/press-start-2p-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/press-start-2p-v12-latin-regular.svg#PressStart2P') format('svg'); /* Legacy iOS */
}
.Background {
  background: no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.logo {
  margin: 0;
  position: absolute;
  top: 36%;
  left: 50%;
  width: 600px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* Desktop/Tablet Design */
@media only screen and (min-width: 1000px) { 
  .legalNotice {
    margin: 0;
    position: absolute;
    bottom: -1%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .answer {
    margin: 0;
    position: absolute;
    top: 80%;
    left: 50%;
    width: 100vh;
    max-height: 15vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .rechts {
    position: absolute;
    right: 4%;
    top: 37vh;
    width: 400px;
  }
  .mitte {
    position: absolute;
    left: 40%;
    top: 20vh;
    width: 400px;
  }
  .links {
    position: absolute;
    left: 4%;
    top: 37vh;
    width: 400px;
  }
  .textscroll {
    margin-left: -30px;
    width: 100vh;
    max-height: 13vw;
    overflow-y: scroll;
  }
  .resulttextscroll {
    margin-left: -30px;
    width: 62vh;
    max-height: 13vw;
    overflow-y: scroll;
  }
  .resultbutton {
    margin-top: 10px;
    width: 148%;
  }
  .resultbox {
    width: 600px;
  }
  .resultboxend {
    width: 600px;
  }
}
/* Handy Design */
@media only screen and (max-width: 1000px) {
  .logo {
    margin: 0;
    position: absolute;
    top: 25%;
    left: 50%;
    width: 600px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .legalNotice {
    position: absolute;
    top: 6%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .answer {
    margin: 0;
    position: absolute;
    top: 70%;
    left: 50%;
    width: 110vh;
    max-height: 20vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .rechts {
    position: absolute;
    right: -1%;
    top: 15vh;
    width: 250px;
  }
  .mitte {
    position: absolute;
    left: 36%;
    top: 2vh;
    width: 250px;
  }
  .links {
    position: absolute;
    left: -1%;
    top: 15vh;
    width: 250px;
  }
  .textscroll {
    margin-left: -30px;
    width: 107vh;
    height: 13vw;
    overflow-y: scroll;
  }
  .resulttextscroll {
    position: absolute;
    top: 16vh;
    left: 50%;
    width: 140vh;
    max-height: 7vw;
    overflow-y: scroll;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .resultbutton {
    position: absolute;
    top: 35vh;
    width: 135%;
    left: 55%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .resultbox {
    width: 550px;
    margin-top: -22vh;
    height: 30vh;
    position: absolute;
    left: 55%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .resultboxend {
    width: 550px;
    margin-top: 0vh;
    height: 65vh;
    position: absolute;
    left: 55%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
@import "/node_modules/nes.css/css/nes.css"
